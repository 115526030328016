import React, { Component, Fragment } from 'react'
import { Col, Container, Row, Button } from 'react-bootstrap'
import PDetails from'../../asset/images/pdetails.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import RestClient from '../../RestAPI/RestClient'
import AppUrl from '../../RestAPI/AppUrl'
import ReactHtmlParser from 'react-html-parser';

class ProjectDetails extends Component {
  constructor(props){
    super();
    this.state={
      myProjectID:props.id,
      project_name:"...",
      main_img:"...",
      project_description:"...",
      project_features:"...",
      live_preview:"..."
    }
  }
  componentDidMount(){
    RestClient.GetRequest(AppUrl.ProjectDetails+this.state.myProjectID).then(response=>{
      this.setState({
        project_name:response[0]['project_name'],
        main_img:response[0]['main_img'],
        project_description:response[0]['project_description'],
        project_features:response[0]['project_features'],
        live_preview:response[0]['live_preview']
      })
    })
  }
  render() {
    return (
        <Fragment>
            <Container className='mt-5'>
                <Row>
                    <Col lg={6} md={6} sm={12}>
                      <div className='aboutThumbWrap afterShape'>
                        <img src={this.state.main_img} />
                      </div>
                    </Col>
                    
                    <Col lg={6} md={6} sm={12} className='mt-5'>
                      <div className='projectDetails'>
                      <h6 className='sing-line'>About</h6>
                        <h1 className='projectHead'>{this.state.project_name}</h1>
                        <p className='projectDesc'>{this.state.project_description}</p>
                        <p className="cardSubTitle text-justify"><FontAwesomeIcon className="iconBullent" icon={faCheckSquare}  />{ReactHtmlParser(this.state.project_features)}</p>
                        <Button variant='info' href={this.state.live_preview}>Live Preview</Button>
                      </div>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
  }
}

export default ProjectDetails
