import React, {Component, Fragment} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import LoaderIcon from'../../asset/images/loader.svg'

class Loading extends Component{
    render(){
        return(
            <Fragment>
                <Container className='text-center'>
                    <Row>
                        <Col>
                            <img class="preloader" src={LoaderIcon} alt="" />
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
    
}
export default Loading