import React, { Component, Fragment } from 'react'
import TopNavigation from '../components/TopNavigation/TopNavigation'
import PageTop from '../components/PageTop/PageTop'
import Footer from '../components/Footer/Footer'
import ProjectDetails from '../components/ProjectDetails/ProjectDetails'

class ProjectDetailPage extends Component {
  constructor({match}){
    super();
    this.state={
      projectPassedID:match.params.projectID,
      projectPassedName:match.params.projectName,
    }
  }
  componentDidMount(){
    window.scroll(0,0)
  }
  render() {
    return (
     <Fragment>
        <TopNavigation title="Academic Zone - Project Detail" />
        <PageTop pageTitle={this.state.projectPassedName} />
        <ProjectDetails id={this.state.projectPassedID} />
        <Footer />

     </Fragment>
    )
  }
}

export default ProjectDetailPage
 