import React, {Component , Fragment} from 'react'
import {Col, Container, Row, Form , Button} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faHouse, faMap, faPhone } from '@fortawesome/free-solid-svg-icons'
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import Loading from '../Loading/Loading';
import WentWrong from '../WentWrong/WentWrong';
import Zoom from 'react-reveal/Zoom';



class ContactSec extends Component{
    constructor(){
        super();
        this.state={
            address: "...",
            email: "...",
            phone: "...",
            loading: true,
            error: false
        }
    }
    componentDidMount(){
        RestClient.GetRequest(AppUrl.FooterData).then(result=>{
            if(result == null){
                this.setState({error:true, loading:false})
            }else{
            this.setState({
                address:result[0]['address'],
                email:result[0]['email'],
                phone:result[0]['phone'],
                loading:false
            });
        }
        }).catch(error => {
            this.setState({error:true,loading:false})
        })
    }

    sendCont(){
        let name = document.getElementById("name").value;
        let email = document.getElementById("email").value;
        let message = document.getElementById("message").value;
        let course_name = document.getElementById("course_name").value;
        // alert(name+"/"+email+"/"+message)
        let JsonObject = {course_name:course_name,name:name,mail:email,message:message}

        RestClient.PostRequest(AppUrl.ContactSend,JSON.stringify(JsonObject)).then(result=>{
            alert(result);
        }).catch(error=>{
            alert("Error");
        })
    }



    render(){
        if(this.state.loading){
            return <Loading />
        }
        else if(this.state.error){
            return <WentWrong />
        }else{
        return(
            <Fragment>
                <Container className='mt-5'>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <Zoom>
                            <h1 className='serviceName'>Quick Connect</h1>
                            <Form>
                                <Form.Group className='mb-2'>
                                    <Form.Label>Your Name</Form.Label>
                                    <Form.Control id="name" type="text" placeholder="Enter Your Name" />
                                </Form.Group>
                                <Form.Group className='mb-2'>
                                    <Form.Label>Your Email</Form.Label>
                                    <Form.Control id="email" type="email" placeholder="Enter Your Email" />
                                </Form.Group>
                                <Form.Group className='mb-2'>
                                    <Form.Label>Your Message</Form.Label>
                                    <Form.Control id="message" as="textarea" rows={3} />
                                </Form.Group>
                                <Form.Control id="course_name" type="hidden" value="Contact Form"/>
                                <Button onClick={this.sendCont} variant="primary" >
                                    Submit
                                </Button>
                            </Form>
                            </Zoom>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                        <Zoom>
                        <h1 className='serviceName'>Discuss Now</h1>
                        <p className="serviceDescription">
                        <FontAwesomeIcon icon={faHouse}/>&nbsp;  Add : {this.state.address}<br />
                        <FontAwesomeIcon icon={faEnvelope}/>&nbsp;  Email : {this.state.email}<br />
                        <FontAwesomeIcon icon={faPhone}/>&nbsp;  Phone : {this.state.phone}
                        </p>
                        </Zoom>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    } //end else
   
    }
} 
export default ContactSec