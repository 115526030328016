import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import face from '../../asset/images/face.png';
import { init } from 'ityped'
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import Loading from '../Loading/Loading';
import WentWrong from '../WentWrong/WentWrong';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import LightSpeed from 'react-reveal/LightSpeed';

class AboutMe extends Component {
    constructor(){
        super();
        this.state={
            about_img : '',
            sub_title : '',
            title : '',
            before_animate : '',
            animated_text:'',
            loading: true,
            error: false
        }
    }
    componentDidMount(){
        RestClient.GetRequest(AppUrl.AboutMe).then(result=>{
            if(result == null){
                this.setState({error:true,loading:false})
            }else{
            this.setState({
                about_img:result[0]['about_img'],
                sub_title:result[0]['sub_title'],
                title:result[0]['title'],
                before_animate:result[0]['before_animate'],
                animated_text:result[0]['animated_text'],
                loading:false
            }, () => {
                // Ensure this code runs after the state is updated
                const myAbout = document.querySelector('#myAbout');
                const animated_text = this.state.animated_text;
                if (myAbout) {
                    init(myAbout, { showCursor: false, strings: [animated_text, 'Online Instructor!'] });
                }
            });
        }
        }).catch(error => {
            this.setState({error: true})
        })
    }
  render() {
    if(this.state.loading == true){
        return <Loading />
    }else if(this.state.loading == false){
    return (
        <Fragment>
            <Container className="text-center">
                <LightSpeed>
                <h1 className='serviceMainTitle'>About Me</h1>
                <div className='bottom'></div>
                </LightSpeed>
                <Row>
                    <Col lg={6} md={6} sm={12}>
                     {this.state.about_img && (
                                <Fade left>
                                <div className="aboutMe">
                                    <Slide top>
                                    <img className="faceImg" src={this.state.about_img} alt="About Me" />
                                    </Slide>
                                </div>
                                </Fade>
                            )}
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                        <div className="aboutMeBody">
                        <Slide top>
                        <h2 className="aboutMeDetails">{this.state.sub_title}</h2>
                        <h2 className="aboutMeTitle">{this.state.title}</h2>
                        <h3 className="aboutMeDetails">{this.state.before_animate} <span id='myAbout'></span></h3>
                        </Slide>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
  }
  else if(this.state.error == true){
    return <WentWrong />
  }
} 

}

export default AboutMe
