import React, { Component, Fragment } from 'react'
import PageTop from '../components/PageTop/PageTop'
import TopNavigation from '../components/TopNavigation/TopNavigation'
import NotFound from '../components/NotFound/NotFound'
import Footer from '../components/Footer/Footer'
import error from '../asset/images/error.jpg'

class PageNotFound extends Component {
  render() {
    return (
      <Fragment>
        <TopNavigation title="Error: 404 Page Not Found!" />
        <PageTop pageTitle="Page Not Found!" />
        <NotFound error={error}/>
        <Footer />
      </Fragment>
    )
  }
}

export default PageNotFound
