import React, { Component, Fragment } from 'react'
import TopNavigation from '../components/TopNavigation/TopNavigation'
import PrivacyDescription from '../components/PrivacyDescription/PrivacyDescription'
import Footer from '../components/Footer/Footer'
import PageTop from '../components/PageTop/PageTop'

class PrivacyPage extends Component {
  componentDidMount(){
    window.scroll(0,0) 
  }
  render() {
    return (
        <Fragment>
            <TopNavigation title="Academic Zone - Privacy Policy" />
            <PageTop pageTitle="Privacy Policy" />
            <PrivacyDescription />
            <Footer />
        </Fragment>
    )
  }
}

export default PrivacyPage
