import React, { Component, Fragment } from 'react'
import TopNavigation from '../components/TopNavigation/TopNavigation'
import TopBanner from '../components/TopBanner/TopBanner'
import AllProjects from '../components/AllProjects/AllProjects'
import Footer from '../components/Footer/Footer'
import PageTop from '../components/PageTop/PageTop'

class PortfolioPage extends Component {
  componentDidMount(){
    window.scroll(0,0)
  }
  render() {
    return (
        <Fragment>
            <TopNavigation title="Academic Zone - Our Portfolio"  />
            <PageTop pageTitle="Our Portfolio" />
            <AllProjects />
            <Footer />
        </Fragment>

    )
  }
}

export default PortfolioPage
