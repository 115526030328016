import React, { Component, Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  Col, Container, Modal, Row, Button, } from 'react-bootstrap'
import { faVideoSlash } from '@fortawesome/free-solid-svg-icons'
import 'video-react/dist/video-react.css'
import { Player, PosterImage } from 'video-react'
import ReactHtmlParser from 'react-html-parser';
import RestClient from '../../RestAPI/RestClient'
import AppUrl from '../../RestAPI/AppUrl'
import LightSpeed from 'react-reveal/LightSpeed';
import Slide from 'react-reveal/Slide';
import Loading from '../Loading/Loading';




class Videos extends Component {
    constructor(){
        super();
        this.state={
            show:false,
            video_description: "",
            video_url: "",
            video_thumb: "",
            loading: true
        }
    }
    componentDidMount(){
      RestClient.GetRequest(AppUrl.HomeVideo).then(result=>{
        this.setState({
          video_description:result[0]['video_description'],
          video_url:result[0]['video_url'],
          video_thumb:result[0]['video_thumb'],
          loading:false
        })
      })
    }
    modalClose=()=>this.setState({show:false})
    modalOpen=()=>this.setState({show:true})
  render() {
    if(this.state.loading == true){
      return <Loading />
    }else{
    return (
      <Fragment>
        <Container className="text-center">
            <LightSpeed>
            <h1 className='technoloyTitle'>Our Videos</h1>
            <div className='bottom'></div>
            </LightSpeed>
            <Row>
                <Col lg={6} md={6} sm={12} className="videoText">
                  <Slide top>
                    <p className="serviceDescription text-justify">
                    { ReactHtmlParser(this.state.video_description) }
                   </p>
                  </Slide>
                </Col>
                <Col lg={6} md={6} sm={12} className="videoCard">
                    <FontAwesomeIcon onClick={this.modalOpen} className="iconProject" icon={faVideoSlash} />
                </Col>
            </Row>
        </Container>
        
        <Modal size="lg" show={this.state.show} onHide={this.modalClose}>
        <Modal.Body>
        <Player
          poster ={this.state.video_thumb}
          src={this.state.video_url}

        />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.modalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      </Fragment>
    )
  }// end else
  }
}

export default Videos
