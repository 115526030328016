import React, { Component, Fragment } from 'react'
import { Card, Col, Container, Row , Button} from 'react-bootstrap'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import Loading from '../Loading/Loading';
import Slide from 'react-reveal/Slide';
import LightSpeed from 'react-reveal/LightSpeed';


class RecentProject extends Component {
    constructor(){
        super();
        this.state={
            data:[],
            loading: true,
        }
    }

    componentDidMount(){
        RestClient.GetRequest(AppUrl.ProjectHome).then(result=>{
            this.setState({data:result , loading:false});
        });
    }
  render() {
    if (this.state.loading == true) {
        return <Loading />
    }else{
    const MyLink = this.state.data;
    const MyView = MyLink.map(item=>{
        return   <Col lg={4} md={6} sm={12}>
                    <Slide top>
                    <Card className='projectCard'>
                        <Card.Img variant="top" src={item.thumb_img} />
                        <Card.Body>
                            <Card.Title className='serviceName'>{item.project_name}</Card.Title>
                            <Card.Text className='serviceDescription'>
                            {item.project_description}
                            </Card.Text>
                            <Button variant="primary"><Link className="linkStyle" to={"/project_detail/"+item.id+"/"+item.project_name}>View More</Link></Button>
                        </Card.Body>
                    </Card>
                    </Slide>
                </Col>
    })
    return (
        <Fragment>
            <Container className='text-center'>
                <LightSpeed>
                <h1 className='technoloyTitle recentProj'>RECENT PROJECTS</h1>
                <div className='bottom'></div>
                </LightSpeed>
                <Row>
                    {MyView}
                </Row>
            </Container>
        </Fragment>
    )
}// end else
  }
}

export default RecentProject
