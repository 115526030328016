import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser';
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import Loading from '../Loading/Loading';
import WentWrong from '../WentWrong/WentWrong';
import Zoom from 'react-reveal/Zoom';
import LightSpeed from 'react-reveal/LightSpeed';


class RefundDescription extends Component {
  constructor(){
    super();
    this.state={
      refundDesc: "",
      loading:true,
      error: false
    }
  }

  componentDidMount(){
    RestClient.GetRequest(AppUrl.Information).then(response =>{
      if(response == null){
        this.setState({error:true,loading:false})
      }else{
      this.setState({refundDesc:response[0]['refund'], loading:false});
      }
    }).catch(error =>{
      this.setState({error:true})
    })
  }
  render() {
    if(this.state.loading == true){
      return <Loading />
    }else if(this.state.loading == false){
    return (
      <Fragment>
        <Container className='mt-5'>
            <Row>
                <Col lg={12} md={12} sm={12}>
                <LightSpeed>
                <h1 className="serviceName">Refund Policies</h1>
                </LightSpeed>
                <hr />
                <Zoom>
                <p className='serviceDescription'>
                { ReactHtmlParser(this.state.refundDesc) }
                {/* {this.state.refundDesc } */}
                </p>
                </Zoom>
                </Col>
            </Row>
        </Container>
      </Fragment>
    )
  } // end else
  else if(this.state.error == true){
    return<WentWrong/>
  }
  }
}

export default RefundDescription
