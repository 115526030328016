import React, { Component, Fragment } from 'react'
import TopNavigation from '../components/TopNavigation/TopNavigation'
import PageTop from '../components/PageTop/PageTop'
import AboutDescription from '../components/AboutDescription/AboutDescription'
import Footer from '../components/Footer/Footer'
import AboutMe from '../components/AbouMe/AboutMe'

class AboutPage extends Component {
  componentDidMount(){
    window.scroll(0,0)
  }
  render() {
    return (
        <Fragment>
            <TopNavigation title="Academic Zone - About Us"  />
            <PageTop pageTitle ="About Us"/>
            <AboutMe />
            <AboutDescription />
            <Footer />
        </Fragment>
    )
  }
}

export  default AboutPage;
