import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ErrorIcon from'../../asset/images/error.svg'

class WentWrong extends Component {
  render() {
    return (
        <Fragment>
            <Container>
                <Row>
                    <Col className='text-center'>
                    <img className="errorIcon" src={ErrorIcon} alt="" />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
  }
}

export default WentWrong
