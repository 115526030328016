import React, { Component, Fragment } from 'react'
import { Card, Col, Container, Row , Button} from 'react-bootstrap'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import Loading from '../Loading/Loading';
import WentWrong from '../WentWrong/WentWrong';
import Slide from 'react-reveal/Slide';
import LightSpeed from 'react-reveal/LightSpeed';


class AllProjects extends Component {
    constructor(){
        super();
        this.state={
            allData:[],
            loading:true,
            error: false
        }
    }
    componentDidMount(){
        RestClient.GetRequest(AppUrl.ProjectAll).then(result=>{
            if(result == null){
                this.setState({error:true,loading:false})
            }else{
            this.setState({allData:result, loading:false})
            }
        }).catch(error =>{
            this.setState({error:true,loading:false})
        })
    }
  render() {
    if(this.state.loading== true){
        return <Loading />
    }
    else if(this.state.loading == false){
    const MyLink = this.state.allData;
    const MyView = MyLink.map(items=>{
        return <Col lg={4} md={6} sm={12}>
        <Slide top>
        <Card className='projectCard'>
            <Card.Img variant="top" src={items.thumb_img} />
            <Card.Body>
                <Card.Title className='serviceName'>{items.project_name}</Card.Title>
                <Card.Text className='serviceDescription'>
                {items.project_description}
                </Card.Text>
                <Button variant="primary"><Link className="linkStyle" to={"/project_detail/"+items.id+"/"+items.project_name}>View More</Link></Button>
            </Card.Body>
        </Card>
        </Slide>
        </Col>
    })
    return (
        <Fragment>
            <Container className='text-center'>
                <LightSpeed>
                <h1 className='technoloyTitle'>RECENT PROJECTS</h1>
                <div className='bottom'></div>
                </LightSpeed>
                <Row>
                    {MyView}
                </Row>
            </Container>
        </Fragment>
    )
    } // end else
    else if(this.state.error == true){
        return <WentWrong />
    }//end else if
  }
}

export default AllProjects
