import React, { Component, Fragment } from 'react'
import { Nav, NavDropdown, Navbar } from 'react-bootstrap'
import whiteLogo from '../../asset/images/logo_white.png';
import blackLogo from '../../asset/images/logo_black.png';
import '../../asset/css/custom.css';
import '../../asset/css/responsive.css';
import {NavLink} from "react-router-dom";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';



class TopNavigation extends Component {
    constructor(props){
        super();
        this.state={
            navBarTitle: "navTitle", //class
            navBarLogo: [whiteLogo], //object
            navVariant: "dark",
            navBarBack: "navBackground",
            navBarItem: "navItem",
            pageTitle:props.title
        }
    }
        onScroll=()=>{
            if(window.scrollY>100){
                this.setState({navBarTitle:'navTitleScroll', navBarLogo:[blackLogo],navBarBack:'navBackgroundScroll',navBarItem:'navItemScroll',navVariant: 'light'})
            }else if(window.scrollY<100){
                this.setState({navBarTitle:'navTitle', navBarLogo:[whiteLogo],navBarBack:'navBackground',navBarItem:'navItem',navVariant: 'dark'})
            }
        }
        componentDidMount(){
            window.addEventListener('scroll', this.onScroll)
        }

    render() {
        return (
            <Fragment>
                <title>{this.state.pageTitle}</title>
                <Navbar className={this.state.navBarBack} collapseOnSelect fixed="top" expand="lg" variant={this.state.navVariant}>
                    <Navbar.Brand className={this.state.navBarTitle}><Link to="/    "><img src={this.state.navBarLogo} /></Link></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            
                        </Nav>
                        <Nav>
                            <NavLink exact activeStyle={{color: '#ffd900'}} className={this.state.navBarItem} to="/">HOME</NavLink>
                            <NavLink exact activeStyle={{color: '#ffd900'}} className={this.state.navBarItem} to="/about">ABOUT</NavLink>
                            <NavLink exact activeStyle={{color: '#ffd900'}} className={this.state.navBarItem} to="/service">SERVICES</NavLink>
                            <NavLink exact activeStyle={{color: '#ffd900'}} className={this.state.navBarItem} to="/course">COURSES</NavLink>
                            <NavLink exact activeStyle={{color: '#ffd900'}} className={this.state.navBarItem} to="/portfolio">PORTFOLIO</NavLink>
                            <NavLink exact activeStyle={{color: '#ffd900'}} className={this.state.navBarItem} to="/contact">CONTACT US</NavLink>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Fragment>
        )
    }
}

export default TopNavigation
