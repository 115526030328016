import React, { Component, Fragment } from 'react'
import { Button, Col, Container, Row ,Modal, Form} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faClock, faClipboard, faClone, faTags, faCheckSquare, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Player, BigPlayButton } from 'video-react';
import ReactPlayer from 'react-player';
import ReactHtmlParser from 'react-html-parser';
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';

class CourseDetails extends Component {
    constructor(props){
        super();
        this.state={
            show:false,
        }
    }
    modalClose=()=>this.setState({show:false})
    modalOpen=()=>this.setState({show:true})
    sendCont(){
        let name = document.getElementById("name").value;
        let email = document.getElementById("email").value;
        let message = document.getElementById("message").value;
        let course_name = document.getElementById("course_name").value;
        // alert(name+"/"+email+"/"+message)
        let JsonObject = {course_name:course_name,name:name,mail:email,message:message}

        RestClient.PostRequest(AppUrl.ContactSend,JSON.stringify(JsonObject)).then(result=>{
            alert(result);
        }).catch(error=>{
            alert("Error");
        }) 
    }
  render() {
    let allMyCourse = this.props.myCourse;
    let course_img = "";
    let LongTitle = "";
    let long_desc = "";
    let skills_left_side = "";
    let skills_right_side = "";
    let total_students = "";
    let total_duration = "";
    let total_lectures = "";
    let categories = "";
    let tags = "";
    let instructor = "";
    let price = "";
    let video_url = "";


    if (allMyCourse.length == 1){
        
          course_img = allMyCourse[0]['course_img'];
          LongTitle = allMyCourse[0]["long_title"];
          long_desc = allMyCourse[0]['long_desc'];
          skills_left_side = allMyCourse[0]['skills_left_side'];
          skills_right_side = allMyCourse[0]['skills_right_side'];
          total_students = allMyCourse[0]['total_students'];
          total_duration = allMyCourse[0]['total_duration'];
          total_lectures = allMyCourse[0]['total_lectures'];
          categories = allMyCourse[0]['categories'];
          tags = allMyCourse[0]['tags'];
          instructor = allMyCourse[0]['instructor'];
          price = allMyCourse[0]['price'];
          video_url = allMyCourse[0]['video_url'];
    }
    return (
        <Fragment>
            <Container className='mt-5'>
                <Row>
                    <Col lg={8} md={6} sm={12}>
                        <h1 className='courseDetailTitle' >{LongTitle}</h1>
                        <img className='courseDetailBanner' src={course_img} /><br /><br />
                        <p className='courseDetailDesc text-justify'>
                        {ReactHtmlParser(long_desc)}
                        </p>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <div className='widgetFeature'>
                            <h4 className='widgetTitle text-center'>Course Features</h4>
                            <hr/>
                            <ul>
                            <li><FontAwesomeIcon className="iconBullent" icon={faUser} /><span> Enrolled : </span>{total_students} Students</li>
                            <li><FontAwesomeIcon className="iconBullent" icon={faClock} /><span> Duration : </span>{total_duration} Hours</li>
                            <li><FontAwesomeIcon className="iconBullent" icon={faClipboard} /><span> Lectures : </span> {total_lectures}</li>
                            <li><FontAwesomeIcon className="iconBullent" icon={faClone} /><span> Categories : </span>{categories}</li>
                            <li><FontAwesomeIcon className="iconBullent" icon={faTags} /><span> Tags : </span>{tags}</li>
                            <li><FontAwesomeIcon className="iconBullent" icon={faCheckSquare} /><span> Instructor : </span>{instructor}</li>
                            </ul>
                            <div className='price-wrap text-center'>
                                <h5>Price : <span>${price}</span></h5>
                                <Button variant="warning" onClick={this.modalOpen}>ENROLL COURSE</Button>
                            </div>
                        </div><br />
                        <div>
                        <ReactPlayer className="video-wrapper" controls url={video_url} />
                        </div>
                    </Col>
                </Row>
            </Container><br /><br />
            <Container>
                <Row>
                    <Col lg={12} md={12} sm={12}>
                        <div className='skills'>
                            <h1 className='courseDetailTitle'>Skills You Need</h1><hr/><br />
                            <Row>
                            <Col lg={6} md={6} sm={12}>
                            {ReactHtmlParser(skills_left_side)}
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                            {ReactHtmlParser(skills_right_side)}
                            </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Modal size="lg" show={this.state.show} onHide={this.modalClose}>
            <Modal.Footer> 
          <Button variant="secondary" onClick={this.modalClose}>
          <FontAwesomeIcon className="iconBullent" icon={faTimes} />
          </Button>
        </Modal.Footer>
            <Modal.Body>
            <Form>  
                <Form.Group className='mb-2'>
                    <Form.Label>Your Name</Form.Label>
                    <Form.Control id="name" type="text" placeholder="Enter Your Name" />
                </Form.Group>
                <Form.Group className='mb-2'>
                    <Form.Label>Your Email</Form.Label>
                    <Form.Control id="email" type="email" placeholder="Enter Your Email" />
                </Form.Group>
                <Form.Group className='mb-2'>
                    <Form.Label>Your Message</Form.Label>
                    <Form.Control id="message" as="textarea" rows={3} />
                </Form.Group>
                <Form.Control id="course_name" type="hidden" value={`Course Name: ${LongTitle}`}/>
                <br/>
                <Button onClick={this.sendCont} variant="primary" >
                    Submit
                </Button><br/>
            </Form>
            </Modal.Body>
        
      </Modal>


        </Fragment>
    )
  }
}

export default CourseDetails
