import { faFacebook, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faHouse, faMap, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import RestClient from '../../RestAPI/RestClient'
import AppUrl from '../../RestAPI/AppUrl'
import Loading from '../Loading/Loading'
import WentWrong from '../WentWrong/WentWrong'

class Footer extends Component {
    constructor(){
        super();
        this.state={
            address: "...",
            email: "...",
            phone: "...",
            facebook: "...",
            youtube: "...",
            twitter: "...",
            footer_cre: "...",
            loaderClass: "p-5 text-justify",
            mainDivClass: "d-none",
            error: false
        }
    }
    componentDidMount(){
        RestClient.GetRequest(AppUrl.FooterData).then(result=>{
            if(result == null){
                this.setState({error:true})
            }else{
            this.setState({
                address:result[0]['address'],
                email:result[0]['email'],
                phone:result[0]['phone'],
                facebook:result[0]['facebook'],
                youtube:result[0]['youtube'],
                twitter:result[0]['twitter'],
                footer_credit:result[0]['footer_credit'],
                loaderClass: "d-none",
                mainDivClass: "p-5 text-justify"
            
            });
        }
        }).catch(error =>{
            this.setState({error:true})
        })
    }
  render() {
    if(this.state.error == false){
    return (
        <Fragment>
            <Container fluid={true} className="footerSection">
                <Row>
                    <Col lg={3} md={6} sm={12} className="p-5 text-center mob-padd">
                        <h2 className="FooterName text-center">Follow Us</h2>
                        <div className="socialContainer">
                        <Link className="facebook social" to={this.state.facebook}><FontAwesomeIcon icon={faFacebook} size="2x" /></Link>
                        <Link className="youtube social" to={this.state.youtube}><FontAwesomeIcon icon={faYoutube} size="2x" /></Link>
                        <Link className="twitter social" to={this.state.twitter}><FontAwesomeIcon icon={faTwitter} size="2x" /></Link>
                        </div>
                    </Col>

                    <Col className={this.state.loaderClass}><Loading /></Col>
                    <Col lg={3} md={6} sm={12} className={` mob-padd ${this.state.mainDivClass}`}>
                    <h2 className="FooterName">Address</h2>
                    <p className="footerDescription">
                        <FontAwesomeIcon icon={faHouse}/>&nbsp;{this.state.address}<br />
                        <FontAwesomeIcon icon={faEnvelope}/>&nbsp;  Email : {this.state.email}<br />
                        <FontAwesomeIcon icon={faPhone}/>&nbsp;  Phone : {this.state.phone}
                    </p>
                    </Col>

                    <Col className={this.state.loaderClass}><Loading /></Col>
                    <Col lg={3} md={6} sm={12} className={` mob-padd ${this.state.mainDivClass}`}>
                    <h2 className="FooterName">Information</h2>
                    <Link className="footerLinks" to="about">About Me</Link><br />
                    <Link className="footerLinks" to="portfolio">Company Profile</Link><br />
                    <Link className="footerLinks" to="contact">Contact Us</Link>
                    </Col>

                    <Col className={this.state.loaderClass}><Loading /></Col>
                    <Col lg={3} md={6} sm={12} className={` mob-padd ${this.state.mainDivClass}`}>
                    <h2 className="FooterName">Our Policy</h2>
                    <Link className="footerLinks" to="refund_policy">Refund Policy</Link><br />
                    <Link className="footerLinks" to="terms_and_conditions">Terms And Conditions</Link><br />
                    <Link className="footerLinks" to="privacy_policy">Privacy Policy</Link>
                    </Col>
                </Row>
            </Container>
            <Container fluid={true} className="text-center copyRightSection">
            <Link className="footerLinks" to="/">{this.state.footer_credit}</Link> 
            </Container>
        </Fragment>
    )
}else if(this.state.error == true){
    return <WentWrong />
}
  }

}

export default Footer
