import React, { Component, Fragment } from 'react'
import TopNavigation from '../components/TopNavigation/TopNavigation'
import PageTop from '../components/PageTop/PageTop'
import CourseDetails from '../components/CourseDetails/CourseDetails'
import Footer from '../components/Footer/Footer'
import RestClient from '../RestAPI/RestClient'
import AppUrl from '../RestAPI/AppUrl'

class CourseDetailPage extends Component {
  constructor({match}){
    super();
    this.state={
      coursePassedID: match.params.courseID,
      coursePassedName: match.params.courseName,
      courseData: [],
    }
  }
  componentDidMount(){ 
    window.scroll(0,0)

    RestClient.GetRequest(AppUrl.CourseDetails+this.state.coursePassedID).then(result=>{
      this.setState({courseData:result});
    })
  }
  render() {
    return (
        <Fragment>
            <TopNavigation title="Academic Zone - Course Detail" />
            <PageTop pageTitle={this.state.coursePassedName} />
            <CourseDetails myCourse={this.state.courseData} />
            <Footer />
        </Fragment>
    )
  }
}

export default CourseDetailPage
