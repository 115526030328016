import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

class NotFound extends Component {
  render() {
    return (
      <Fragment>
        <Container>
            <Row>
                <Col className="text-center">
                    <h1><img src={this.props.error} alt="" width="500px" height="500px" /></h1>
                    {/* <h1 className="notFound">404</h1> */}
                </Col>
            </Row>
        </Container>
      </Fragment>
    )
  }
}

export default NotFound
