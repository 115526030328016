import React, { Component, Fragment } from 'react'
import { Col, Container, Row  } from 'react-bootstrap'
import { Bar, BarChart, ResponsiveContainer, XAxis, Tooltip } from 'recharts';
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import ReactHtmlParser from 'react-html-parser';
import LightSpeed from 'react-reveal/LightSpeed';
import Slide from 'react-reveal/Slide';



class Analysis extends Component {
    constructor(){
        super();
        this.state={
            data:[],
            techDesc: "..."
        }
    }
    componentDidMount(){
      RestClient.GetRequest(AppUrl.ChartData).then(response=>{
        this.setState({data:response});
      });
      RestClient.GetRequest(AppUrl.HomeTechDesc).then(result=>{
        this.setState({techDesc:result[0]['tech_description']});
      });
    }

  render() {
    var blue = "#051b35";
    return (
      <Fragment>
        <Container className='text-center'>
            <LightSpeed>
            <h1 className='technoloyTitle'>TECHNOLOGY USED</h1>
            <div className='bottom'></div>
            </LightSpeed>
            <Row>
                <Col style={{width:'100%', height:'300px',}} lg={6} md={12} sm={12}>
                <ResponsiveContainer>
                <BarChart width={100} height={300} data={this.state.data}>
                
                <XAxis dataKey="Technology" />
                <Tooltip />
                <Bar dataKey="Projects" fill={blue} ></Bar>

                </BarChart>
                </ResponsiveContainer>
                </Col>
                <Col lg={6} md={12} sm={12}>
                <Slide top>
                <p className='technologyDes text-justify'>
                { ReactHtmlParser(this.state.techDesc) }
                </p>
                </Slide>
                </Col>
            </Row>
        </Container>
      </Fragment>   
    )
  }
}

export default Analysis
