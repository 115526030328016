import React, { Component, Fragment } from 'react'
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import HomePage from '../pages/HomePage'
import AboutPage from '../pages/AboutPage';
import AllServicePage from '../pages/AllServicePage';
import AllCoursePage from '../pages/AllCoursePage';
import PortfolioPage from '../pages/PortfolioPage';
import ContactPage from '../pages/ContactPage';
import RefundPage from '../pages/RefundPage';
import TermsPage from '../pages/TermsPage';
import PrivacyPage from '../pages/PrivacyPage';
import ProjectDetailPage from '../pages/ProjectDetailPage';
import CourseDetailPage from '../pages/CourseDetailPage';
import NotFound from '../components/NotFound/NotFound';
import PageNotFound from '../pages/PageNotFound';

class AppRouter extends Component {
  render() {
    return (
      <Fragment>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/about" component={AboutPage} />
          <Route exact path="/service" component={AllServicePage} />
          <Route exact path="/course" component={AllCoursePage} />
          <Route exact path="/portfolio" component={PortfolioPage} />
          <Route exact path="/contact" component={ContactPage} />

          <Route exact path="/refund_policy" component={RefundPage} />
          <Route exact path="/terms_and_conditions" component={TermsPage} />
          <Route exact path="/privacy_policy" component={PrivacyPage} />
          
          <Route exact path="/project_detail/:projectID/:projectName" component={ProjectDetailPage} />
          <Route exact path="/course_detail/:courseID/:courseName" component={CourseDetailPage} />
        
          <Route component={PageNotFound} />
        </Switch>
      </Fragment>
    )
  }
}

export default AppRouter
