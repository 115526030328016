import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser';
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import Loading from '../Loading/Loading';
import WentWrong from '../WentWrong/WentWrong';
import Zoom from 'react-reveal/Zoom';


class AboutDescription extends Component {
    constructor(){
        super();
        this.state={
            aboutdesc: "",
            loading: true,
            error: false
        }
    };
    componentDidMount(){
        RestClient.GetRequest(AppUrl.Information).then(response=>{
            if(response == null){
                this.setState({error:true, loading: false});
            }else{
            this.setState({aboutdesc:response[0]['about'],loading:false});
            }
        }).catch(error =>{
            this.setState({error:true, loading: false})
        })
    }
  render() {
    if(this.state.loading === true){
        return <Loading/>
    }
    else if(this.state.loading === false){
    return (
        <Fragment>
            <Container className="mt-5">
                <Row>
                    <Col lg={12} md={12} sm={12}>
                    <Zoom>
                    { ReactHtmlParser(this.state.aboutdesc) }
                    </Zoom>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}else if(this.state.error === true){
    return <WentWrong />
} //end else if
  }
}

export default AboutDescription
