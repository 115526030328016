import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import designIcon from '../../asset/images/design.png';
import ecommerceIcon from '../../asset/images/ecommerce.png';
import webIcon from '../../asset/images/web.png';
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import Loading from '../Loading/Loading';
import WentWrong from '../WentWrong/WentWrong';
import Fade from 'react-reveal/Fade';
import LightSpeed from 'react-reveal/LightSpeed';



export class Services extends Component {
    constructor(){
        super()
        this.state={
            myData:[],
            loading: true,
            error: false
        }
    }

    componentDidMount(){
        RestClient.GetRequest(AppUrl.Services).then(result=>{
            if(result == null){
                this.setState({error:true,loading:false})
            }else{
            this.setState({myData:result, loading: false});
            }
        }).catch(error => {
            this.setState({error:true,loading:false})
        })
    }
  render() {
    if(this.state.loading == true){
        return <Loading />
    }else if(this.state.loading == false){
    const MyLink = this.state.myData;
    const MyView = MyLink.map(MyLink =>{
        return <Col lg={4} md={6} sm={12}>
                    <Fade top>
                    <div className= 'serviceCard text-center'>
                        <img className='serviceIcon' src={MyLink.service_logo} alt="" />
                        <h2 className='serviceName'>{MyLink.service_name}</h2>
                        <p className='serviceDescription'>{MyLink.service_description}</p>
                    </div>
                    </Fade>
                </Col>
    })
    return (
        <Fragment>
            <Container className='text-center'>
                <LightSpeed>
                <h1 className='serviceMainTitle'>Our Services</h1>
                <div className='bottom'></div>
                </LightSpeed>
                <Row>
                    {MyView}
                </Row>
            </Container>
        </Fragment>
    )
}// end else  if
else if(this.state.error == true){
    return <WentWrong/>
  }
  }
}

export default Services
