import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import LightSpeed from 'react-reveal/LightSpeed';
import Fade from 'react-reveal/Fade';
import Loading from '../Loading/Loading';

 class Courses extends Component {
    constructor(){
        super();
        this.state={
            data:[],
            loading: true
        }
    }
    componentDidMount(){
        RestClient.GetRequest(AppUrl.CourseHome).then(result=>{
            this.setState({data:result,loading:false})
        })
    }

  render() {
    if(this.state.loading == true){
        return <Loading />
    }else{
    const MyLink = this.state.data;
    const MyView = MyLink.map(item=>{
        return  <Col lg={6} md={6} sm={6}>
                    <Row> 
                        <Col lg={6} md={6} sm={12} className="p-3">
                        <Fade top>
                            <img className="courseImg" src={item.course_img} alt="" />
                        </Fade>
                        </Col>
                        
                        <Col lg={6} md={6} sm={12}>
                            <Fade top>
                            <h5 className="text-justify serviceName">{item.short_title}</h5>
                            <p className="text-justify serviceDescription">{item.short_desc}</p>
                            </Fade>
                            <Link className="courseViewMore float-left" to={"/course_detail/"+item.id+"/"+item.short_title}>View Details</Link>
                        </Col> 
                    </Row>
                </Col>
                  
    });
    return (
        <Fragment>
            <Container className="text-center"> 
                <LightSpeed>
                <h1 className='technoloyTitle'>Our Courses</h1>
                <div className='bottom'></div>
                </LightSpeed>
                <Row>
                    {MyView}
                </Row>
            </Container>
        </Fragment>
    )
}// end else
  }
}

export default Courses
