import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import LightSpeed from 'react-reveal/LightSpeed';
import Fade from 'react-reveal/Fade';
import Loading from '../Loading/Loading';




class ClientReview extends Component {
  constructor(){
    super();
    this.state={
      data:[],
      loading: true
    }
  }
  componentDidMount(){
    RestClient.GetRequest(AppUrl.ClientReview).then(response=>{
      this.setState({data:response, loading: false});
    });
  }

  render() {
    if(this.state.loading == true){
      return <Loading />
    }else{
    var settings = {
        autoplay: true,
        autoplaySpeed: 3000,
        dots: true,
        infinite: true,
        speed: 3000,
        vertical: true,
        arrows: false,
        verticalSwiping: true, 
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll:1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
      const MyLink = this.state.data;
      const MyView = MyLink.map(MyLink=>{
        return  <div>
          <Row className="text-center justify-content-center">
          <Col lg={6} md={6} sm={12}>
                    <Fade bottom>
                    <img className="circleImg" src={MyLink.client_img}alt="" />
                    <h2 className='clientName'>{MyLink.client_name}</h2>
                    <p className='reviewDescription'>{MyLink.client_description}</p>
                    </Fade>
                </Col>
          </Row>
        </div>
        
      })
    return (
        <Fragment>
            <Container fluid={true} className="sliderBack text-center">
                <LightSpeed>
                <h1 className='reviewTitle pt-5'>TESTIMONIALS</h1>
                <div className='reviewBottom'></div>
                </LightSpeed>
                <Slider {...settings}>
                    {MyView}
                </Slider>
            </Container>
        </Fragment>
    )
  }// end else
  }
}

export default ClientReview
