class AppUrl{
    // static BaseUrl = 'http://127.0.0.1:8000/api;
    static BaseUrl = 'https://academicbackend.ideal-fashion.pk/api';

    static HomeTopTitle = this.BaseUrl+'/homepage/title';
    static HomeTechDesc = this.BaseUrl+'/techhome';
    static TotalHomeDetails = this.BaseUrl+'/totalhome';
    static HomeVideo = this.BaseUrl+'/home/video';

    static ProjectDetails = this.BaseUrl+'/projectdetails/';
    static ProjectAll = this.BaseUrl+'/projectall';
    static ProjectHome = this.BaseUrl+'/projecthome';

    static Services = this.BaseUrl+'/services';

    static Welcome = this.BaseUrl+'/welcome';

    static Information = this.BaseUrl+'/information';

    static AboutMe = this.BaseUrl+'/aboutme';

    static FooterData = this.BaseUrl+'/footerdata';

    static CourseDetails = this.BaseUrl+'/coursedetails/';
    static CourseAll = this.BaseUrl+'/courseall';
    static CourseHome = this.BaseUrl+'/coursehome';

    static ClientReview = this.BaseUrl+'/clientreview';

    static ContactSend = this.BaseUrl+'/contactsend';

    static ChartData = this.BaseUrl+'/chartdata';
}
export default AppUrl