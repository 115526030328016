import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import Loading from '../Loading/Loading';
import WentWrong from '../WentWrong/WentWrong';
import LightSpeed from 'react-reveal/LightSpeed';
import Fade from 'react-reveal/Fade';

class AllCourses extends Component {
    constructor(){
        super();
        this.state={
            allData:[],
            loading: true,
            error: false
        }
    }
    componentDidMount(){
        RestClient.GetRequest(AppUrl.CourseAll).then(response=>{
            if(response == null){
                this.setState({error:true,loading:false});
            }else{
            this.setState({allData:response,loading:false})
            }
        }).catch(error =>{
            this.setState({error:true})
        })
    }
  render() {
    if(this.state.loading == true){
        return <Loading />
    }
    else if(this.state.loading == false){
    const MyLink = this.state.allData;
    const MyView = MyLink.map(result=>{
        return                     <Col lg={6} md={6} sm={6}>
        <Row>
            <Col lg={6} md={6} sm={12} className="p-3">
            <Fade top>
                <img className="courseImg" src={result.course_img} alt="" />
            </Fade>
            </Col>
            <Col lg={6} md={6} sm={12}>
                <Fade top>
                <h5 className="text-justify serviceName">{result.short_title}</h5>
                <p className="text-justify serviceDescription">{result.short_desc}</p>
                </Fade>
                <Link className="courseViewMore float-left" to={"/course_detail/"+result.id+"/"+result.short_title}>View Details</Link>
            </Col>
        </Row>
    </Col>
    })
    return (
        <Fragment>
            <Container className="text-center"> 
                <LightSpeed>
                <h1 className='technoloyTitle'>Our Courses</h1>
                <div className='bottom'></div>
                </LightSpeed>
                <Row>

                   {MyView}
                </Row>
            </Container>
        </Fragment>
    )
} //end else
else if(this.state.error == true){
    return <WentWrong />
}//end else if
  }
}

export default AllCourses
