import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import pageOne from '../../asset/images/page1.png'
import pageTwo from '../../asset/images/page2.png'
import pageThree from '../../asset/images/page3.png'
import imgOne from '../../asset/images/19.png'
import imgTwo from '../../asset/images/20.png'
import imgThree from '../../asset/images/21.png'
import Slide from 'react-reveal/Slide';
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import Loading from '../Loading/Loading'
import WentWrong from '../WentWrong/WentWrong'

class Welcome extends Component {
    constructor(){
        super();
        this.state={
            data:[],
            loading: true,
            error: false
        };
    }
    componentDidMount(){
        RestClient.GetRequest(AppUrl.Welcome).then(result=>{
            if(result==null){
                this.setState({error:true,loading:false});
            }else{
                this.setState({data:result, loading:false});
            }
        }).catch(error=>{
            this.setState({error:true,loading:false});
        })
    }
  render() {
    if(this.state.loading==true){
        return <Loading />
    }else if(this.state.loading == false){
        const MyLinks = this.state.data;
        const MyView = MyLinks.map(result=>{
            return <Col lg={4} md={6} sm={12}>
            <Slide top>
            <img src={result.welcome_ser_img} />
            </Slide>
            <h1 className='serviceName'>{result.welcome_ser_title}</h1>
            <p className='serviceDescription'>{result.welcome_ser_desc}</p>
        </Col>
        });
        const MyView2 = MyLinks.map(result=>{
            return <Col lg={4} md={6} sm={12}>
            <Row>
                <Col lg={6} md={6} sm={12}>
                    <img className='sideImg' src={result.welcome_bottom_img} alt="" />
                </Col>
                <Col lg={6} md={6} sm={12}>
                <h5 className=' text-justify imgIntro'>{result.welcome_bottom_title}</h5>   
                <p className='text-justify serviceDescription imgdesc'>{result.welcome_bottom_desc}</p>
                </Col>
            </Row>
            </Col>    
        });
    return (
        <Fragment>
            <div className='intro-area-top'>
                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='section-title text-center'>
                                <div className='intro-area-inner'>
                                    <Slide top>
                                    <h6 className='sub-title double-line'>WELCOME</h6>
                                    <h2 className='mainTitle'>To Amazing<br/>Learning Community </h2>
                                    </Slide>
                                    <Container className='text-center mt-5'>
                                        <Row>
                                          {MyView}
                                            
                                        </Row>
                                        {/* intro footer start */}
                                            <Row className='intro-footer bg-base text-center mt-5'>
                                            {MyView2}     
                                            </Row>
                                    </Container>                                
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Fragment>        
    )
}// end elseif
    else if(this.state.error == true){
        return <WentWrong />
    }
  }
}

export default Welcome
